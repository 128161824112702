import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import {
	Button,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import Loading from '../../utils/Loading';
import QRCode from 'react-qr-code';
import { currencyFormat } from '../../utils/numberFormat';

const PrintPreviewModal = ({ isOpen, onClose, onClickPrint }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/bet/ticket/${sessionStorage.getItem(
		'accessTicketId',
	)}`;
	const authToken = sessionStorage.getItem('accessToken');

	const fetchTicketApi = () => {
		axios
			.get(apiUrl, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				setData(response.data.data);
				setIsLoading(false);
			})
			.catch(() => {});
	};

	useEffect(() => {
		fetchTicketApi();
	}, []);

	if (isLoading)
		return (
			<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
				<ModalOverlay />
				<ModalContent width='100%' pl='10%'>
					<Loading />
				</ModalContent>
			</Modal>
		);

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent width='100%'>
				<ModalHeader>Print Preview</ModalHeader>
				<ModalCloseButton />
				{!isLoading && (
					<>
						<ModalBody
							marginX={5}
							paddingX={3}
							borderColor='#E5E5E5'
							borderWidth={1}
							borderRadius={8}
							overflowY='scroll'
							// overscrollBehaviorX='none'
							overflowX='none'
							maxHeight='50vh'
							css={{
								'&::-webkit-scrollbar': {
									display: 'none', // hide scroll bar（仅在WebKit浏览器中有效，例如Chrome和Safari）
								},
								scrollbarWidth: 'none', // hide scroll bar（仅在非WebKit浏览器中有效，例如Firefox）
							}}
						>
							<Flex justifyContent='space-between'>
								<Text>Agent Name:</Text>
								<Text maxW='200px' textAlign='right' width={['48vw']} warp='warp'>
									{data[0]?.firstName}
								</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Bet Date:</Text>
								<Text>{dayjs(data[0]?.date).format('DD/MM/YYYY')}</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Bet Time:</Text>
								<Text>{dayjs(data[0]?.date).format('hh:mm A')}</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Draw Time:</Text>
								<Text>{dayjs(data[0]?.drawTime).format('hh:mm A')}</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Game Type:</Text>
								<Text>{data[0]?.siteName}</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Ticket No:</Text>
								<Text>{data[0]?.ticketNumber}</Text>
							</Flex>
							<Flex justifyContent='space-between'>
								<Text>Ticket Status:</Text>
								<Text>{data[0]?.status}</Text>
							</Flex>

							<Divider marginY={2} borderWidth='1px' borderColor='#000' />
							<Flex flexDir='column'>
								<Flex
									justifyContent='space-between'
									width='100%'
									marginY={2}
									backgroundColor='#eeeeee'
									p={2}
								>
									<Text>Games</Text>
									<Text>Numbers</Text>
									<Text>Deals</Text>
									<Text>Bets</Text>
								</Flex>
								{data[0]?.betting?.map((item, index) => {
									return (
										<Fragment key={index}>
											{item?.isFail === false && (
												<Flex flexDirection='column' my={2}>
													<Flex
														flexDirection='row'
														justifyContent='space-between'
														width='100%'
														// px={2}
													>
														<Text width='10%'>
															{item?.gameType === 'SWER3'
																? '3D'
																: item?.gameType === 'RG7' || item?.gameType === 'MANDAUE'
																? '3D'
																: '2D'}
														</Text>
														<Flex justifyContent='space-between' width='40%'>
															{item.number.map((item, index) => {
																return (
																	<Fragment key={index}>
																		<Text>{item.digit}</Text>
																	</Fragment>
																);
															})}
															<Text width='30%' textAlign='right'>
																{item.lp === true ? '(LP)' : ''}
															</Text>
															<Text width='10%'>{item.ramble === true ? 'R' : 'S'}</Text>
														</Flex>

														<Text width='10%' color='green.500'>
															Succ
														</Text>
														<Text width='15%' textAlign='right'>
															{currencyFormat(Number(item.betPrice))}
														</Text>
													</Flex>
													<Flex>
														<Text>Odds:&nbsp;{Number(item.winRate).toFixed(2)}</Text>
													</Flex>
												</Flex>
											)}
										</Fragment>
									);
								})}
								<Flex
									justifyContent='space-between'
									width='100%'
									backgroundColor='#eeeeee'
									p={2}
									marginY={4}
								>
									<Text>Total Success</Text>
									<Text>{currencyFormat(Number(data[0]?.totalSuccessPrice))}</Text>
								</Flex>

								{data[0]?.betting?.map((item, index) => {
									return (
										<Fragment key={index}>
											<Flex justifyContent='space-between' width='100%' px={2}>
												{item?.isFail === true && (
													<>
														<Text width='10%'>
															{item?.gameType === 'SWER3'
																? '3D'
																: item?.gameType === 'RG7' || item?.gameType === 'MANDAUE'
																? '3D'
																: '2D'}
														</Text>
														<Flex justifyContent='space-between' width='40%'>
															{item.number.map((item, index) => {
																return (
																	<Fragment key={index}>
																		<Text>{item.digit}</Text>
																	</Fragment>
																);
															})}
															<Text width='30%' textAlign='right'>
																{item.lp === true ? '(LP)' : ''}
															</Text>
															<Text width='10%'>{item.ramble === true ? 'R' : 'S'}</Text>
														</Flex>

														<Text width='10%' color='red.500'>
															Fail
														</Text>
														<Text width='15%' textAlign='right'>
															{currencyFormat(Number(item.betPrice))}
														</Text>
													</>
												)}
											</Flex>
										</Fragment>
									);
								})}
							</Flex>

							<Flex
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginTop={8}
							>
								<QRCode
									style={{
										width: '70px',
										height: 'auto',
									}}
									value={data[0]?.qrString ?? '0'}
								/>
								<Text marginY={2}>Ticket Number: {data[0]?.ticketNumber}</Text>
							</Flex>
						</ModalBody>

						<ModalFooter>
							{data[0].isPrinted === false ? (
								<Button
									colorScheme='blue'
									width='100px'
									alignSelf='flex-end'
									onClick={onClickPrint}
								>
									Print
								</Button>
							) : data[0].allowReprint === true ? (
								<Flex flexDirection='column'>
									<Button
										colorScheme='blue'
										width='100px'
										alignSelf='flex-end'
										onClick={onClickPrint}
									>
										Reprint
									</Button>
									<Text fontSize='10px' color='blue.500'>
										*Contact System Admin to reprint ticket.
									</Text>
								</Flex>
							) : (
								<Flex flexDirection='column'>
									<Button
										// colorScheme='teal'
										width='100px'
										alignSelf='flex-end'
										disabled
										// onClick={onClickPrint}
									>
										Reprint
									</Button>
									<Text fontSize='10px' color='blue.500'>
										*Contact System Admin to reprint ticket.
									</Text>
								</Flex>
							)}
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	);
};

export default PrintPreviewModal;
