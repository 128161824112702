import {
	Box,
	CircularProgress,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { getUserProfileApi } from '../../api/query/profileApi';
import ImgComp from '../../utils/ImgComp';
import { ConvertedTime } from '../../utils/Time';
import { currencyFormat } from '../../utils/numberFormat';

const SuccFailModal = ({ isOpen, onClick, data, isLoading }) => {
	const navigate = useNavigate();
	// state
	const [download, setDownload] = useState(false);
	const [dataPcso, setDataPcso] = useState([]);
	const [dataStl, setDataStl] = useState([]);
	const [isFailDataPcso, setIsFailDataPcso] = useState([]);
	const [isFailDataStl, setIsFailDataStl] = useState([]);
	const [openQR, setOpenQR] = useState(false);

	//* api
	const userId = sessionStorage.getItem('userId');
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);

	//download Image
	const downloadImage = () => {
		const component = document.getElementById('my-component'); // replace with the ID of your component
		html2canvas(component).then((canvas) => {
			const link = document.createElement('a');
			link.download = `image${ConvertedTime({ time: dayjs(), format: 'DD/MM/YYYY hhmmss' })}.png`;
			link.href = canvas.toDataURL();
			link.click();
			window.ReactNativeWebView.postMessage(
				JSON.stringify({ function: 'download', base64: canvas.toDataURL() }),
			);
		});
	};

	// useEffect
	useEffect(() => {
		setDataPcso([]);
		setDataStl([]);
		setIsFailDataPcso([]);
		setIsFailDataStl([]);

		var filterPcso = data.betting.filter(
			(item) => (item.gameType === 'EZ2' || item.gameType === 'SWER3') && item.isFail === false,
		);
		var filterStl = data.betting.filter(
			(item) => (item.gameType === 'RG7' || item.gameType === 'MANDAUE') && item.isFail === false,
		);
		var filterIsFailPcso = data.betting.filter(
			(item) => (item.gameType === 'EZ2' || item.gameType === 'SWER3') && item.isFail === true,
		);

		var filterIsFailStl = data.betting.filter(
			(item) => (item.gameType === 'RG7' || item.gameType === 'MANDAUE') && item.isFail === true,
		);
		setDataPcso(filterPcso);
		setDataStl(filterStl);
		setIsFailDataPcso(filterIsFailPcso);
		setIsFailDataStl(filterIsFailStl);
	}, [data]);

	return (
		<>
			<Modal
				isCentered
				closeOnOverlayClick={false}
				size={['xs', 'xs', 'md', 'md', 'md']}
				isOpen={isOpen}
			>
				<ModalOverlay />
				{isLoading ? (
					<ModalContent alignItems='center' padding={10} marginX={3}>
						<CircularProgress isIndeterminate size='120px' color='blue.600' />
					</ModalContent>
				) : download ? (
					<ModalContent marginX={3}>
						<ModalBody
							id='my-component'
							borderWidth={1}
							borderRadius={8}
							borderColor='blue.500'
							marginX={[4]}
							height={[download ? '100vh' : '50vh']}
							overflowY={download ? 'none' : 'scroll'}
							alignItems='center'
							paddingBottom={5}
							paddingX={[2, 2, 4, 5]}
							position='relative'
						>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Ticket No.: &nbsp;
								</Text>
								<Text
									fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
									fontWeight='500'
									color='blue.500'
								>
									{data.ticketNumber}
								</Text>
							</Flex>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Bet Time:&nbsp;
								</Text>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									{ConvertedTime({ time: data.date, format: 'DD/MM/YYYY hh:mm A' })}
								</Text>
							</Flex>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Draw Time:&nbsp;
								</Text>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									{ConvertedTime({ time: data.drawTime, format: 'DD/MM/YYYY hh:mm A' })}
								</Text>
							</Flex>
							<Box paddingBottom={10}>
								{/* //! Success Pcso Bet----------------------- */}
								{dataPcso.length > 0 && (
									<DataCard
										titleColor='green.500'
										label='Successful bet lists'
										data={dataPcso}
										totalBetPrice={data?.totalSuccessPrice}
										download={download}
										length={dataPcso.length}
									/>
								)}
								{/* //! Fail Pcso Bet----------------------- */}
								{isFailDataPcso.length > 0 && (
									<DataCard
										titleColor='red.500'
										label='Failed bet lists'
										data={isFailDataPcso}
										totalBetPrice={data?.totalFailPrice}
										download={download}
										length={isFailDataPcso.length}
									/>
								)}

								{/* //! Success Stl Bet----------------------- */}
								{dataStl?.length > 0 && (
									<DataCard
										titleColor='green.500'
										label='Successful bet lists'
										data={dataStl}
										totalBetPrice={data?.totalSuccessPrice}
										download={download}
										length={dataStl.length}
									/>
								)}
								{/* //! Fail Stl Bet----------------------- */}
								{isFailDataStl?.length > 0 && (
									<DataCard
										titleColor='red.500'
										label='Failed bet lists'
										data={isFailDataStl}
										totalBetPrice={data?.totalFailPrice}
										download={download}
										length={isFailDataStl.length}
									/>
								)}
							</Box>
							<Flex width='100%' flexDirection='column' paddingX={0} borderRadius={8}>
								<Flex
									justifyContent='space-between'
									marginX={['6px', '6px', '10px', '10px', '18px']}
								>
									<Text
										width={[window.ReactNativeWebView ? '70%' : '59%']}
										fontWeight='500'
										fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
										color='green.500'
									>
										Total Success Amount (PHP)
									</Text>
									<Text
										width={[window.ReactNativeWebView ? '30%' : '38%']}
										textAlign='right'
										fontWeight='600'
										fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
										color='green.500'
									>
										{currencyFormat(Number(data.totalSuccessPrice))}
									</Text>
								</Flex>
								<Flex
									justifyContent='space-between'
									marginX={['6px', '6px', '10px', '10px', '18px']}
								>
									<Text
										width={[window.ReactNativeWebView ? '70%' : '59%']}
										fontWeight='500'
										fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
										// color='#000000'
										color='red.400'
									>
										Total Failed Amount (PHP)
									</Text>
									<Text
										width={[window.ReactNativeWebView ? '30%' : '38%']}
										textAlign='right'
										fontWeight='600'
										fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
										color='red.400'
									>
										{currencyFormat(Number(data.totalFailPrice))}
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				) : (
					<ModalContent maxHeight={window.ReactNativeWebView ? '70vh' : '65vh'} marginX={3}>
						<ModalHeader position='relative'>
							<Box onClick={onClick} position='absolute' top='5' right='5' cursor='pointer'>
								<ImgComp svgName='close-icon' />
							</Box>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Ticket No.: &nbsp;
								</Text>
								<Text
									fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
									fontWeight='500'
									color='blue.500'
								>
									{data.ticketNumber}
								</Text>
							</Flex>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Bet Time:&nbsp;
								</Text>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									{ConvertedTime({ time: data.date, format: 'DD/MM/YYYY hh:mm A' })}
								</Text>
							</Flex>
							<Flex flexDirection={['column', 'column', 'column', 'row']}>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									Draw Time:&nbsp;
								</Text>
								<Text fontSize={['14px', '16px', '16px', '16px', '16px', '20px']} fontWeight='500'>
									{ConvertedTime({ time: data.drawTime, format: 'DD/MM/YYYY hh:mm A' })}
								</Text>
							</Flex>
						</ModalHeader>
						<ModalBody
							borderWidth={1}
							borderRadius={8}
							borderColor='blue.500'
							marginX={[4]}
							height='50vh'
							overflowY={download ? 'none' : 'scroll'}
							alignItems='center'
							paddingX={[2, 2, 4, 5]}
						>
							<Box paddingBottom={10}>
								{/* //! Success Pcso Bet----------------------- */}
								{dataPcso?.length > 0 && (
									<DataCard
										titleColor='green.500'
										label='Successful bet lists'
										data={dataPcso}
										totalBetPrice={data?.totalSuccessPrice}
										length={dataPcso.length}
										download={download}
									/>
								)}
								{/* //! Fail Pcso Bet----------------------- */}

								{isFailDataPcso?.length > 0 && (
									<DataCard
										titleColor='red.500'
										label='Failed bet lists'
										data={isFailDataPcso}
										totalBetPrice={data?.totalFailPrice}
										length={isFailDataPcso.length}
										download={download}
									/>
								)}

								{/* //! Success Stl Bet----------------------- */}
								{dataStl?.length > 0 && (
									<DataCard
										titleColor='green.500'
										label='Successful bet lists'
										data={dataStl}
										totalBetPrice={data?.totalSuccessPrice}
										length={dataStl.length}
										download={download}
									/>
								)}
								{/* //! Fail Stl Bet----------------------- */}

								{isFailDataStl?.length > 0 && (
									<DataCard
										titleColor='red.500'
										label='Failed bet lists'
										data={isFailDataStl}
										totalBetPrice={data?.totalFailPrice}
										length={isFailDataStl.length}
										download={download}
									/>
								)}
							</Box>
						</ModalBody>
						<ModalFooter>
							<Flex width='100%' flexDirection='column' paddingX={0} borderRadius={8}>
								<Flex
									justifyContent='space-between'
									marginX={['6px', '6px', '10px', '10px', '18px']}
								>
									<Text
										width={window.ReactNativeWebView ? ['70%'] : ['59%']}
										fontWeight='500'
										fontSize={
											window.ReactNativeWebView
												? ['14px']
												: ['14px', '16px', '16px', '16px', '16px', '20px']
										}
										// color='#000000'
										color='green.500'
									>
										Total Success Amount (PHP)
									</Text>
									<Text
										width={window.ReactNativeWebView ? ['30%'] : ['38%']}
										textAlign='right'
										fontWeight='600'
										fontSize={
											window.ReactNativeWebView
												? ['16px']
												: ['14px', '16px', '16px', '16px', '16px', '20px']
										}
										color='green.500'
									>
										{currencyFormat(Number(data.totalSuccessPrice))}
									</Text>
								</Flex>
								<Flex
									justifyContent='space-between'
									marginX={['6px', '6px', '10px', '10px', '18px']}
								>
									<Text
										width={window.ReactNativeWebView ? ['70%'] : ['59%']}
										fontWeight='500'
										fontSize={
											window.ReactNativeWebView
												? ['14px']
												: ['14px', '16px', '16px', '16px', '16px', '20px']
										}
										// color='#000000'
										color='red.400'
									>
										Total Failed Amount (PHP)
									</Text>
									<Text
										width={window.ReactNativeWebView ? ['30%'] : ['38%']}
										textAlign='right'
										fontWeight='600'
										fontSize={
											window.ReactNativeWebView
												? ['16px']
												: ['14px', '16px', '16px', '16px', '16px', '20px']
										}
										color='red.400'
									>
										{currencyFormat(Number(data.totalFailPrice))}
									</Text>
								</Flex>
								<Flex flexDirection='row'></Flex>
								<Flex
									flexDirection='row'
									justifyContent='space-between'
									alignItems='center'
									width='100%'
									marginTop={4}
								>
									<Text
										onClick={() => {
											sessionStorage.setItem('accessTicketId', data.ticketId);
											navigate('/history/ticketdetail');
										}}
										color='blue.350'
										textDecoration='underline'
										fontWeight={500}
										cursor='pointer'
										userSelect='none'
									>
										View Ticket
									</Text>
									<Flex>
										{getUserProfile.data?.isAgent === true && (
											<Flex
												onClick={() => {
													setOpenQR(true);
												}}
												width='25px'
												marginRight={5}
												userSelect='none'
												cursor='pointer'
											>
												<ImgComp svgName='qr-code' />
											</Flex>
										)}
										<Flex
											onClick={() => {
												if (download === false) {
													setDownload(true);
													setTimeout(() => {
														downloadImage();
													}, 100);
													setTimeout(() => {
														setDownload(false);
													}, 150);
												}
											}}
											userSelect='none'
											cursor='pointer'
										>
											<ImgComp svgName='download' />
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</ModalFooter>
					</ModalContent>
				)}
			</Modal>

			{/* modal QR */}
			{openQR && (
				<Modal
					isOpen={openQR}
					onClose={() => {
						setOpenQR(false);
					}}
					isCentered
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalCloseButton />
						<ModalBody>
							<Box align='center' padding={6}>
								<QRCode
									size={60}
									style={{ height: 'auto', maxWidth: '60%', width: '60%' }}
									value={data.qrString}
								/>
								<Text paddingTop={4}>Ticket Number: {data.ticketNumber}</Text>
							</Box>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default SuccFailModal;
const TableHeader = () => {
	return (
		<Flex
			marginTop={2}
			height={['32px', '32px', '42px']}
			flexDirection='row'
			backgroundColor='blue.230'
			borderRadius={'8px 8px 0px 0px'}
			alignItems='center'
			justifyContent='space-between'
			paddingX={['2px', '', '', '', '8px']}
		>
			<Text
				width={['20%']}
				fontWeight='400'
				fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
				color='#000000'
			>
				Games
			</Text>
			<Text
				width={['40%']}
				fontWeight='400'
				fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
				color='#000000'
				textAlign='center'
			>
				Numbers
			</Text>
			<Text
				width={['38%', '38%', '40%', '40%', '38%']}
				textAlign='right'
				fontWeight='400'
				fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
				color='#000000'
			>
				Bets(PHP)
			</Text>
		</Flex>
	);
};

const DataCard = ({ titleColor, label, data, length, download }) => {
	return (
		<Box>
			<Flex justifyContent='space-between'>
				<Text marginY={2} color={titleColor}>
					{label}
				</Text>
				<Text marginY={2} color={titleColor}>
					{length}&nbsp;
					{length === 1 ? 'List' : 'Lists'}
				</Text>
			</Flex>
			<TableHeader />
			<Flex flexDirection='column' backgroundColor='blue.400' borderBottomRadius={8}>
				{data?.map((item, index) => {
					return (
						<Flex
							key={index}
							bgColor={index % 2 === 0 ? 'blue.200' : 'blue.230'}
							flexDirection='row'
							paddingY={[1, 1, 1, 2]}
							paddingX={1}
							alignItems='center'
							justifyContent='space-between'
							borderBottomRadius={data.length - 1 === index ? '10px' : '0px'}
						>
							<Flex width={['22%']}>
								<Text>
									{item.gameType === 'EZ2'
										? '2D'
										: item.gameType === 'RG7' || item.gameType === 'MANDAUE'
										? item.gameType
										: '3D'}
								</Text>
							</Flex>
							<Flex width={['38%']} justifyContent='space-between'>
								<Flex flexDirection='row' marginRight={[1, 2, 3]}>
									{item.number.map((num) => {
										return (
											<Flex key={num.position}>
												<Text marginRight={2}>{num.digit}</Text>
											</Flex>
										);
									})}
								</Flex>
								{item.lp && <Text>(LP)</Text>}

								{item.ramble ? <Text>R</Text> : <Text>S</Text>}
							</Flex>
							<Flex width={['38%']}>
								<Text textAlign='right' width='100%'>
									{currencyFormat(Number(item.betPrice))}
								</Text>
							</Flex>
						</Flex>
					);
				})}

				<Text display={download ? 'block' : 'none'}> &nbsp;</Text>
			</Flex>
		</Box>
	);
};
