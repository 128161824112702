import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ImgComp from '../../utils/ImgComp';
import Loading from '../../utils/Loading';
import { ConvertedTime } from '../../utils/Time';
import { currencyFormat } from '../../utils/numberFormat';
import BetListCard from './BetListCard';
import BetListFlex from './BetListFlex';
import ClaimAgentButton from './ClaimAgentButton';
import SubjectList from './SubjectList';
import VoidButton from './VoidButton';
import { useMutation } from '@tanstack/react-query';
import { render, Printer, QRCode, Text as PrinterText, Row, Br, Line } from 'react-thermal-printer';
import dayjs from 'dayjs';
import { putTicketPrintApi } from '../../api/query/betApi';

const HistoryDetail = () => {
	const [succData, setSuccData] = useState([]);
	const [failData, setFailData] = useState([]);
	const [strikeData, setStrikeData] = useState([]);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/bet/ticket/${sessionStorage.getItem(
		'accessTicketId',
	)}`;
	const authToken = sessionStorage.getItem('accessToken');

	const fetchTicketApi = () => {
		axios
			.get(apiUrl, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				setData(response.data.data);
				setIsLoading(false);
			})
			.catch(() => {});
	};

	// handleWebPrint
	const receipt = (
		<Printer type='epson' width={32} characterSet='pc437_usa' debug={true}>
			{data[0]?.firstName.length <= 16 && (
				<Row left='Agent Name:' right={`${data[0]?.firstName.substring(0, 16)}`} />
			)}
			{data[0]?.firstName.length >= 17 && data[0]?.firstName.length <= 32 && (
				<>
					<Row left='Agent Name:' right={`${data[0]?.firstName.substring(0, 15)}`} />
					<Row left='' right={`${data[0]?.firstName.substring(15, 30)}`} />
				</>
			)}
			{data[0]?.firstName.length >= 33 && data[0]?.firstName.length <= 40 && (
				<>
					<Row left='Agent Name:' right={`${data[0]?.firstName.substring(0, 15)}`} />
					<Row left='' right={`${data[0]?.firstName.substring(15, 30)}`} />
					<Row left='' right={`${data[0]?.firstName.substring(30, 40)}`} />
				</>
			)}
			<Row left='Bet Date:' right={`${dayjs(data[0]?.date).format('DD/MM/YYYY')}`} />
			<Row left='Bet Time:' right={`${dayjs(data[0]?.date).format('hh:mm A')}`} />
			<Row left='Draw Time:' right={`${dayjs(data[0]?.drawTime).format('hh:mm A')}`} />
			<Row left='Game Type:' right={`${data[0]?.siteName}`} />
			<Row left='Ticket No:' right={`${data[0]?.ticketNumber}`} />
			<Row left='Ticket Status:' right={`${data[0]?.status}`} />
			<PrinterText>********************************</PrinterText>
			<Row
				left=''
				center={`${data[0]?.isPrinted === false ? '  Print Copy' : 'Reprint Copy'}`}
				right=''
				gap={10}
			/>
			<PrinterText>********************************</PrinterText>
			<Row left='Games' center='Numbers    Deals ' right='Bets' gap={3} />
			{succData?.map((item, index) => {
				return (
					<Fragment key={index}>
						<PrinterText>
							{item?.isFail === false && item?.gameType === 'SWER3'
								? '3D    '
								: item?.gameType === 'RG7' || item?.gameType === 'MANDAUE'
								? '3D    '
								: '2D    '}
							{item?.number
								?.map((item) => {
									return item?.lp === true && item?.position <= 3
										? item?.digit + ' '
										: item?.digit + ' ';
								})
								.join('')}
							{item.lp === true ? '(LP) ' : '     '}
							{item.ramble === true ? 'R  ' : 'S  '}
							Succ{'  '}
							{Math.floor(Math.log10(item?.betPrice)) + 1 === 5
								? currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 4
								? ' ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 3
								? '   ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 2
								? '    ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 1
								? '     ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 0
								? '     ' + currencyFormat(Number(item?.betPrice))
								: currencyFormat(Number(item?.betPrice))}
							Odds: {Number(item.winRate).toFixed(2)}
						</PrinterText>
					</Fragment>
				);
			})}
			<Line width={32} />
			<Row left='Total Success:' right={`${currencyFormat(Number(data[0]?.totalSuccessPrice))}`} />
			<Line width={32} />
			{failData?.map((item, index) => {
				return (
					<Fragment key={index}>
						<PrinterText>
							{item?.isFail === true && item?.gameType === 'SWER3'
								? '3D    '
								: item?.gameType === 'RG7' || item?.gameType === 'MANDAUE'
								? '3D    '
								: '2D    '}
							{item?.number
								?.map((item) => {
									return item?.lp === true && item?.position <= 3
										? item?.digit + ' '
										: item?.digit + ' ';
								})
								.join('')}
							{item.lp === true ? '(LP) ' : '     '}
							{item.ramble === true ? 'R  ' : 'S  '}
							Fail{'  '}
							{Math.floor(Math.log10(item?.betPrice)) + 1 === 5
								? currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 4
								? ' ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 3
								? '   ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 2
								? '    ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 1
								? '     ' + currencyFormat(Number(item?.betPrice))
								: Math.floor(Math.log10(item?.betPrice)) + 1 === 0
								? '     ' + currencyFormat(Number(item?.betPrice))
								: currencyFormat(Number(item?.betPrice))}
						</PrinterText>
					</Fragment>
				);
			})}
			<Br />
			<QRCode align='center' content={`${data[0]?.ticketNumber}`} cellSize={6} />
			<Br />
			<PrinterText>
				{'  '}Ticket Number: {data[0]?.ticketNumber}
			</PrinterText>
			<Br />
			<Br />
			<Br />
			<Br />
			<Br />
			<Br />
		</Printer>
	);

	const [port, setPort] = useState(null);
	const { mutateAsync: handleWebPrint } = useMutation(async () => {
		handlePrintedTicket.mutate();
		try {
			let _port = port;
			if (_port == null) {
				_port = await navigator?.serial?.requestPort();
				await _port.open({ baudRate: 9600 });
				setPort(_port);
			}
			const writer = _port.writable?.getWriter();
			if (writer != null) {
				const data = await render(receipt);

				await writer.write(data);
				writer.releaseLock();
				setTimeout(async () => {
					await _port.close();
					setPort(null);
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			return null;
		}
	});

	const ticketId = sessionStorage.getItem('accessTicketId');

	const handlePrintedTicket = useMutation({
		mutationFn: () => putTicketPrintApi(ticketId),
		onSuccess: () => {},
		onError: () => {},
	});

	// useEffect
	useEffect(() => {
		fetchTicketApi();
	}, []);

	useEffect(() => {
		if (data !== undefined) {
			const filterFail = data?.[0]?.betting.filter((fail) => fail.isFail === true);
			setFailData(filterFail);
			const filterSucc = data?.[0]?.betting.filter((succ) => succ.isFail === false);
			setSuccData(filterSucc);
			const filterStrike = data?.[0]?.betting.filter((strike) => strike.isStrike === true);
			setStrikeData(filterStrike);
		}
	}, [data]);

	if (isLoading)
		return (
			<Flex height='100vh' justifyContent='center' alignItems='center' width='100vw'>
				<Box width='10vw'></Box>
				<Loading />
			</Flex>
		);

	return (
		<Box
			padding={['2%', '2%', '2%', '2%', '5%']}
			marginY={['25%', '25%', '10%', '5%', '0%']}
			bgGradient='linear(to-b, #F9FAFC, #EDF3FF)'
			width={'100%'}
		>
			<Flex
				backgroundColor={'#ffffff'}
				borderRadius={'8px'}
				padding={['2', '2', '10', '10', '10']}
				justifyContent='center'
				width={'90%'}
				flexDirection='column'
				margin='auto'
				marginY={10}
			>
				<Flex justifyContent='space-between' alignItems='center'>
					<Link to='/history'>
						<ImgComp
							svgName={'left-arrow'}
							width={['28px', '28px', '28px', '32px']}
							height={['28px', '28px', '28px', '32px']}
						/>
					</Link>

					{data?.map((items, index) => {
						return (
							<Fragment key={index}>
								{items.isAgent === true && !items.isDownline && !items.isDenied && !items.isVoid ? (
									<ClaimAgentButton
										ticketNo={items.ticketNumber}
										ticketId={items.ticketId}
										isStrike={items.isStrike}
										isClaimed={items.isClaimed}
										isVoid={items.isVoid}
										isDenied={items.isDenied}
										isExpire={items.isExpire}
										strikeLength={strikeData?.length}
										handleWebPrint={async () => {
											await handleWebPrint();
										}}
									/>
								) : null}
							</Fragment>
						);
					})}
				</Flex>

				<Divider color='#EDEDED' marginY={3} />

				{data?.map((items, index) => {
					return (
						<Box key={index}>
							<SubjectList
								ticketId={items.ticketNumber}
								ticketNumber={items.ticketNumber}
								betDT={ConvertedTime({ time: items.date, format: 'DD/MM/YYYY hh:mm A' })}
								drawDT={ConvertedTime({ time: items.drawTime, format: 'DD/MM/YYYY hh:mm A' })}
								totalBet={
									items.betting?.length > 1
										? `${items.betting?.length} Bets`
										: `${items.betting?.length} Bet`
								}
								totalWins={`${items.winningCount}/${items.betting?.length} Wins`}
								ticketStatus={items.status}
								isExpire={items.isExpire}
								qrString={items.qrString ?? '0'}
								isAgent={items.isAgent}
								firstName={items.firstName}
								mobileNo={items.mobileNo}
								gameType={items.siteName}
							/>

							{/* //!success */}
							{succData?.length > 0 && (
								<>
									<BetSuccess
										voided={data?.[0]?.isVoid}
										success={true}
										numOfList={succData?.length}
									/>
									<BetListTitle showText={true} />

									{succData.map((bet, index) => {
										return (
											<BetListFlex
												key={index}
												showText={true}
												gameType={bet.gameType}
												number={bet.number}
												combineText={bet.lp}
												betType={bet.ramble}
												odds={bet.winRate}
												betTotal={currencyFormat(bet.betPrice)}
												winAmount={bet.winningPrice ?? 0}
											/>
										);
									})}
									<Divider border='1px' borderColor='gray.50' />
									<BetTotalDesktop
										key={index}
										showText={true}
										totalBetPrice={currencyFormat(items.totalSuccessPrice)}
										totalWinAmount={items.winningPrice ?? 0}
										winAmount={items.winningCount}
									/>

									{/* //a succescc mobile size */}
									<Flex
										flexDirection={'column'}
										border='1px'
										borderRadius='8px'
										borderColor={data[0]?.isVoid === true ? 'red.500' : '#0052FF'}
										display={['block', 'block', 'block', 'block', 'block', 'none']}
									>
										{succData.map((bet, index) => {
											return (
												<Fragment key={index}>
													<BetListCard
														backgroundColor={index % 2 === 0 ? 'blue.200' : 'blue.230'}
														showText={true}
														gameType={bet.gameType}
														number={bet.number}
														combineText={bet.lp}
														odds={bet.winRate}
														betType={bet.ramble}
														betTotal={currencyFormat(bet.betPrice)}
														win={items.winningCount ?? false}
														winAmount={bet.winningPrice ?? 0}
														isStrike={bet.isStrike}
													/>
												</Fragment>
											);
										})}
										<BetListTotal
											backgroundColor={succData?.length % 2 === 0 ? 'blue.200' : 'blue.230'}
											winningPrice={items.winningPrice ?? 0}
											isDenied={items.isDenied}
											isVoid={items.isVoid}
										/>
									</Flex>
								</>
							)}

							{/* //!fail */}
							{failData?.length > 0 && (
								<>
									<BetSuccess success={false} numOfList={failData?.length} />
									<BetListTitle showText={false} />
									{failData.map((bet, index) => {
										return (
											<BetListFlex
												key={index}
												showText={false}
												gameType={bet.gameType}
												number={bet.number}
												odds={bet.winRate}
												combineText={bet.lp}
												betType={bet.ramble}
												betTotal={currencyFormat(bet.betPrice)}
											/>
										);
									})}
									<Divider border='1px' borderColor='gray.50' />
									<BetTotalDesktop
										key={index}
										showText={true}
										totalBetPrice={currencyFormat(items.totalFailPrice)}
									/>

									<Flex
										flexDirection={'column'}
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										display={['block', 'block', 'block', 'block', 'block', 'none']}
									>
										{failData.map((bet, index) => {
											return (
												<Fragment key={index}>
													<BetListCard
														backgroundColor={index % 2 === 0 ? 'blue.200' : 'blue.230'}
														showText={false}
														gameType={bet.gameType}
														number={bet.number}
														combineText={bet.lp}
														betType={bet.ramble}
														betTotal={currencyFormat(bet.betPrice)}
													/>
												</Fragment>
											);
										})}
									</Flex>
								</>
							)}
						</Box>
					);
				})}
				{!data?.[0]?.isDownline && <VoidButton />}
			</Flex>
		</Box>
	);
};

export default HistoryDetail;

export const BetSuccess = ({ voided, success, numOfList }) => {
	return (
		<Flex justifyContent='space-between' marginTop='5'>
			<Flex
				color='#22943B'
				fontWeight='400'
				fontSize={['10px', '10px', '10px', '14px', '14px', '18px', '20px']}
			>
				{success ? (
					voided === true ? (
						<Text color='green.600'>Successful bet list</Text>
					) : (
						<Text color='green.500'>Successful bet list</Text>
					)
				) : (
					<Text color='red.400'>Failed bet list</Text>
				)}
			</Flex>

			<Text fontWeight='300' fontSize={['10px', '10px', '10px', '14px', '14px', '18px', '20px']}>
				{numOfList > 1 ? `${numOfList} bets` : `${numOfList} bet`}
			</Text>
		</Flex>
	);
};

export const BetListTitle = ({ showText }) => {
	return (
		<Flex
			// justifyContent={'space-between'}
			fontWeight={'400'}
			fontSize={'20px'}
			padding={3}
			alignItems={'center'}
			bgColor={'#EDF3FF'}
			borderRadius={'8px'}
			display={['none', 'none', 'none', 'none', 'none', 'flex']}
		>
			<Box width={'12%'}>Games</Box>
			<Box width={'22%'}>Numbers</Box>

			<Box width={'15%'} textAlign='right'>
				<Text>Total Bets</Text>
				<Text>(PHP)</Text>
			</Box>

			<Box width='15%' textAlign={'right'}>
				Odds
			</Box>

			<Box
				width={['', '', '', '', '', '12%', '15%']}
				textAlign={'right'}
				visibility={showText ? 'visible' : 'hidden'}
			>
				Result
			</Box>

			<Box
				width={['', '', '', '', '', '24%', '21%']}
				textAlign={'right'}
				visibility={showText ? 'visible' : 'hidden'}
			>
				<Text>Winning Amount</Text>
				<Text> (PHP)</Text>
			</Box>
		</Flex>
	);
};

export const BetTotalDesktop = ({ showText, totalBetPrice, totalWinAmount, winAmount }) => {
	return (
		<Flex
			// justifyContent={'space-between'}
			fontWeight={'500'}
			fontSize={'20px'}
			padding={3}
			display={['none', 'none', 'none', 'none', 'none', 'flex']}
		>
			<Box width={'12%'}>Total</Box>
			<Box width={'22%'} />
			<Box width={'15%'} textAlign={'right'}>
				{totalBetPrice}
			</Box>
			<Box width={'15%'} />
			<Box width={['', '', '', '', '', '12%', '15%']} textAlign={'right'}>
				{totalWinAmount > 0 ? (
					<Text color='#22943B'>{winAmount > 1 ? `${winAmount} Wins` : `${winAmount} Win`}</Text>
				) : (
					'--'
				)}
			</Box>
			<Box
				width={['', '', '', '', '', '24%', '21%']}
				textAlign={'right'}
				color={totalWinAmount > 0 ? '#22943B' : '#000000'}
				visibility={showText ? 'visible' : 'hidden'}
			>
				{totalWinAmount > 0 ? currencyFormat(totalWinAmount) : '--'}
			</Box>
		</Flex>
	);
};

export const BetListTotal = ({ winningPrice, isVoid, isDenied, backgroundColor }) => {
	return (
		<>
			<Flex
				fontSize='14px'
				fontWeight='500'
				padding='2'
				backgroundColor={backgroundColor}
				borderRadius='8px'
			>
				<Text width={['60%', '60%', '50%']}>Total winning Amounts</Text>
				<Text
					width={['40%', '40%', '50%']}
					color={winningPrice > 0 && isVoid === false && isDenied === false ? '#22943B' : '#000000'}
				>
					{winningPrice > 0 && isVoid === false && isDenied === false
						? currencyFormat(winningPrice)
						: '--'}
				</Text>
			</Flex>
		</>
	);
};
